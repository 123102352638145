<template>
    <div class="d-middle">
        <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2 bg-azulclaro">
            <i :class="`${iconUser(residente.tipo)} op-05`" />
        </div>
        <div>
            <p class="f-17 f-500">{{ residente.nombre }}</p>
            <p class="f-13 text-86 tres-puntos"> {{ residente.vivienda }} </p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            residente:'chats/residenteActivo'
        })
    },

}
</script>